






































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import {
  DELETE_SURVEY_QUESTION,
  GET_SURVEY_QUESTIONS
} from "@/store/modules/recruiter/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

import DataNotFound from "@/components/shared/DataNotFound.vue";
import RefreshButton from "@/components/shared/RefreshButton.vue";
import { SurveyQuestionsListingHeaderOptions } from "@/interfaces/settings/account";
import { get_color_by_name } from "@/utils/colors";
import { delete_survey_question } from "@/store/apis/recruiter";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "survey_questions",
  components: {
    DataNotFound,
    RefreshButton
  },
  data() {
    return {
      loading: false, // Loading state of the page
      data_error: false, // Error state of the page
      dialog_box: false,
      table_headers: [
        {
          title: this.$t("survey_questions.listing.col-0").toString(),
          cols: 1,
          sortable: true,
          direction: "asc"
        },
        {
          title: this.$t("survey_questions.listing.col-1").toString(),
          cols: 4,
          sortable: true,
          direction: "asc"
        },
        {
          title: this.$t("survey_questions.listing.col-2").toString(),
          cols: 4,
          sortable: true,
          direction: "asc"
        },
        {
          title: this.$t("survey_questions.listing.col-3").toString(),
          cols: 2
        },
        {
          title: this.$t("survey_questions.listing.col-4").toString(),
          cols: 1
        }
      ] as SurveyQuestionsListingHeaderOptions[],
      status: "",
      delete_icon: require("@/assets/icons/bold/trash.svg"),
      delete_temp_id: 0
    };
  },
  watch: {
    get_site_direction() {
      this.updateTableHeaders();
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      get_survey_questions: GET_SURVEY_QUESTIONS
    }),
    not_found_error(): string {
      return this.$t("recruiter.errors.survey-questions").toString();
    }
  },
  async mounted() {
    await this.fetch_survey_questions(); // Fetch templates on component mount
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_survey_questions_listing: GET_SURVEY_QUESTIONS,
      delete_survey_question: DELETE_SURVEY_QUESTION
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    truncateName(name: string) {
      const maxLength = 20;
      return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
    },
    async fetch_survey_questions() {
      try {
        this.loading = true;
        await this.fetch_survey_questions_listing();
      } catch (error) {
        this.data_error = true;
      } finally {
        this.loading = false;
      }
    },
    handle_delete(id: number) {
      this.delete_temp_id = id;
      this.dialog_box = true;
    },
    async dialog_action(value: boolean) {
      if (value) {
        await this.delete_survey_question_by_id(this.delete_temp_id);
      }
      this.dialog_box = false;
    },
    async delete_survey_question_by_id(id: number) {
      try {
        this.loading = true;
        await delete_survey_question(id);
        await this.fetch_survey_questions();
        this.loading = false;
        this.set_root_notification(this.$t("survey_questions.delete-success"));
      } catch (error) {
        this.set_root_error(this.$t("survey_questions.delete-failed"));
        console.error("Failed to delete survey question:", error);
      }
    },
    updateTableHeaders() {
      this.table_headers = [
        {
          title: this.$t("survey_questions.listing.col-0").toString(),
          cols: 1
        },
        {
          title: this.$t("survey_questions.listing.col-1").toString(),
          cols: 4
        },
        {
          title: this.$t("survey_questions.listing.col-2").toString(),
          cols: 4
        },
        {
          title: this.$t("survey_questions.listing.col-3").toString(),
          cols: 2
        },
        {
          title: this.$t("survey_questions.listing.col-4").toString(),
          cols: 1
        }
      ];
    },
    transform_view_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("success");
      Array.from(icon.children).forEach((node) =>
        node.setAttribute("stroke", color)
      );
      return icon;
    }
  }
});
